.navbar {
    #navbar-offcanvas {
        .navbar-nav {
            .menu-item {
                @include media-breakpoint-down($main-breakpoint) {
                    padding: 0;
                }
                &:last-child {
                    display: inline-block;
                }
                @include media-breakpoint-down($main-breakpoint) {
                    .menu-link-normal {
                        display: block;
                    }
                    .dropdown-menu {
                        margin-top: 0;
                        padding-top: 0;
                        .block-category-link {
                            margin-bottom: map-get($spacers, 2);
                            a {
                                padding: 0;
                                display: block;
                                font-size: $font-size-base;
                                font-weight: $font-weight-light;

                            }
                        }
                    }
                }
            }
        }
    }
}

.menu-item.dropdown {
    .dropdown-menu {
        .block-category-link, .block-category-link-inline{
            a {
                padding: 0;
                font-size: $font-size-base;
            }
        }
    }
}