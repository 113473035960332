footer {
    &.footer {
        .footer-about {
            .footer-block {
                .block-content {

                    p,
                    a {
                        color: $white;
                    }

                    &>p {
                        &:first-child {
                            &::before {
                                @include svg-icon(1.4rem, 1.4rem, $icon-phone-alt, $white);
                                vertical-align: middle;
                            }
                        }
                    }
                }
            }
        }
    }
}
