.cms-home {
    h1.page-title .base,
    .home-products .block-title {
        color: $primary;
    }
}

.home-blocks.row {
    div.home-logo {
        margin-top: -1rem;
        margin-bottom: $spacer;
        padding-bottom: $spacer;
    }

    .logo {
        margin-top: -3rem;
        display: block;
        text-align: center;

        img {
            max-height: 10rem;
            object-fit: contain;
        }
    }
}
