.cookie-status-message {
    display: none;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

p {
    margin: 0 0 10px;
    line-height: $font-size-base * 1.57142857143;
  }