.catalog-category-view {
    .sidebar-main {
        .panel-layered {
            .filter-options-item {
                h4.panel-title {
                    .filter-options-title {
                        color: $primary;
                    }
                }
            }
        }

        .filter-options {
            .amshopby-slider-container {
                &.-default {
                    .am-slider {
                        &-range {
                            position: absolute;
                        }
                        .ui-slider-handle {
                            background-color: $primary !important;
                            width: 1.125rem;
                            height: 1.125rem;
                        }
                    }
                }
            }
        }
    }

    .container-main {
        .products-grid {
            .product-items {
                .product-col {
                    padding: 0;

                    .product-item {
                        margin: 0;

                        .product-item-photo {
                            .product-image-container {
                                width: 100%;

                                .product-image-photo {
                                    width: 100%;
                                }
                            }

                            .product-item-attribute {
                                //right: -5%;

                                .product-label {
                                    &::after {
                                        bottom: -0.725rem;
                                    }
                                }
                            }
                        }
                    }

                    .product-item-configuration {
                        //left: -0.815rem;
                        //right: -0.815rem;
                    }
                }
            }
        }
    }
}
