.pagination {
    margin: 0;
    align-items: center;
    border: 2px solid $secondary;
    font-size: $font-size-sm;
    
    .item {
        padding: 5px 0.625rem;
        
        .page {
            &:hover {
                text-decoration: none;
            }
        }

        &.active {
            span {
                font-weight: $font-weight-bold;
                color: $secondary;
            }
        }

        &.pages-item-previous, 
        &.pages-item-next {
            span {
                color: $primary;
            }
        }
    }
}